<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Contenido
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          ref="refFormObserver"
        >
        <div class="media-list media-bordered mt-3">
          <b-media
            style="align-items: center"
          >
            <template #aside>
              <b-avatar
                size="70px"
                variant="light-secondary"
                :src="eventLocal.extendedProps.imageInflu"
              >
                <feather-icon
                  v-if="eventLocal.extendedProps.imageInflu == ''"
                  icon="UserIcon"
                  size="70px"
                />
              </b-avatar>
            </template>
            <h4 class="media-heading">
              {{eventLocal.extendedProps.name}}
            </h4>
            <p
              v-if="eventLocal.extendedProps.categoryI == 'top-celebrity'"
              class="mb-0"
            >
              <strong>Top Celebrity</strong>
            </p>
            <p
              v-if="eventLocal.extendedProps.categoryI == 'top-influencer-celebrity'"
              class="mb-0"
            >
              <strong>Celebridad / Top Influencer</strong>
            </p>
            <p
              v-if="eventLocal.extendedProps.categoryI == 'microinfluencer'"
              class="mb-0"
            >
              <strong>Micro-influencer</strong>
            </p>
            <p
              v-if="eventLocal.extendedProps.categoryI == 'macroinfluencer'"
              class="mb-0"
            >
              <strong>Macro-influencer</strong>
            </p>
            <p
              v-if="eventLocal.extendedProps.categoryI == 'nanoinfluencer'"
              class="mb-0"
            >
              <strong>Nanoinfluencer</strong>
            </p>
          </b-media>

          <b-row
            class="flex p-2 mt-2"
            style="align-items: center"
          >
            <b-col>
              <b-row>
                <b-col
                  sm="1"
                >
                  <span
                    v-if="eventLocal.extendedProps.network == 'instagram'"
                    style="width: 13px; height: 13px; background: #CD4D4D; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'facebook'"
                    style="width: 13px; height: 13px; background: #1A66F3; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'youtube'"
                    style="width: 13px; height: 13px; background: #CF302B; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'tiktok'"
                    style="width: 13px; height: 13px; background: #EC3F79; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'blog'"
                    style="width: 13px; height: 13px; background: #F0A23F; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'linkedin'"
                    style="width: 13px; height: 13px; background: #346297; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'spotify'"
                    style="width: 13px; height: 13px; background: #64D862; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'twitch'"
                    style="width: 13px; height: 13px; background: #924DF7; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'snapchat'"
                    style="width: 13px; height: 13px; background: #F5D90D; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'twitter'"
                    style="width: 13px; height: 13px; background: #40AAEC; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                  <span
                    v-if="eventLocal.extendedProps.network == 'pinterest'"
                    style="width: 13px; height: 13px; background: #E73631; float: left; margin-right: 0; border-radius: 50%; "
                  ></span>
                </b-col>
                <b-col>
                  <p style="text-transform: capitalize; margin:0;">{{eventLocal.extendedProps.network}}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              Fecha: <strong>{{eventLocal.start.slice(0, 10)}}</strong>
            </b-col>
          </b-row>

          <div
            v-if="eventLocal.extendedProps.description != null"
            class="d-flex p-2"
          >
            <!-- form input -->
            <b-form-group
              class="mb-0 mr-1"
              style="width:80%;"
            >
              <b-form-input
                v-model="eventLocal.extendedProps.description"
              />
            </b-form-group>
            <!-- button -->
            <b-button
              v-clipboard:copy="eventLocal.extendedProps.description"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
            >
              Copiar
            </b-button>
          </div>
          <h5
            class="text-center mt-1"
            v-else
          >Aún no hay contendio publicado</h5>
        </div>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BButton, BMedia, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BSidebar,
    BFormGroup,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
  methods: {
    onCopy() {
      loadToastificationContent().then((component) => {
        this.$toast({
          component: component.default,
          position: 'top-right',
          props: {
            title: this.$t('campaigns.contentCopy'),
            icon: 'CopyIcon',
            variant: "success"
          }
        })
      })
    },
    onError() {
      loadToastificationContent().then((component) => {
        this.$toast({
          component: component.default,
          position: 'top-right',
          props: {
            title: this.$t('campaigns.errorContentCopy'),
            icon: 'alertCircleIcon',
            variant: "danger"
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
