import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'instagram',
      },
      {
        color: 'warning',
        label: 'Family',
      },
      {
        color: 'success',
        label: 'Holiday',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars: ['facebook', 'instagram', 'Family', 'Holiday', 'ETC'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents() {
      const path = window.location.pathname
      const pathArray = path.split('/')
      const index = pathArray.length
      const uuid = pathArray[index - 1]
      return new Promise((resolve, reject) => {
        axios
          .get(`https://sandbox.core.api.brandme.la/api/v1/campaign/${uuid}/manage`)
          .then(response => {
            const info = response.data.response.results
            let x = 0
            const data = []
            info.forEach(arr => {
              const { username } = arr.influencer
              const { calculate_category } = arr.influencer
              const { image_url } = arr.influencer
              arr.content.forEach(cont => {
                if (cont.published_date !== null) {
                  const obj = {
                    id: x,
                    allDay: true,
                    start: cont.published_date.slice(0, 10),
                    end: cont.published_date,
                    title: `${username} - ${cont.network}`,
                    url: '',
                    extendedProps: {
                      name: username,
                      network: cont.network,
                      categoryI: calculate_category,
                      typeContent: cont.content_type,
                      calendar: cont.network,
                      imageInflu: image_url,
                      imageContent: cont.image_content,
                      urlInfo: cont.url_info,
                    },
                    description: cont.url_info,
                    img: cont.image,
                  }
                  data.push(obj)
                  x += 1
                }
              })
            })
            const res = {}
            res.data = data
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
